import { Component, OnInit } from '@angular/core';
import { ContentfulResponse, ContentfulService } from '../../../shared/services/contentful.service'; // Adjust the path as necessary
import { Tile } from '../../../shared/interfaces/shared-interface';
import { IDmsMethodSheet, IDmsOperationalGuide } from '../../../shared/interfaces/contentful';
import { transformTiles } from '../../../shared/transformer/tileTransformer';

@Component({
  selector: 'app-home-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  deliTiles: Tile[] = [];

  operationalTiles: Tile[] = [];

  constructor(private contentfulService: ContentfulService) {}

  ngOnInit(): void {
    this.fetchDeliMethodSheets();
    this.fetchOperationalGuides();
  }

  fetchDeliMethodSheets(): void {
    this.contentfulService.getContentByType<IDmsMethodSheet>('dmsMethodSheet').subscribe((response: ContentfulResponse<IDmsMethodSheet>) => {
      this.deliTiles = transformTiles(response.items);
    });
  }

  fetchOperationalGuides(): void {
    this.contentfulService.getContentByType<IDmsOperationalGuide>('dmsOperationalGuide').subscribe((response: ContentfulResponse<IDmsOperationalGuide>) => {
      this.operationalTiles = transformTiles(response.items);
    });
  }
}
