import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import {
  ActivatedRoute, Router, RouterLink, RouterLinkActive
} from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    RouterLink,
    RouterLinkActive,
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  searchQuery: string = '';

  isOnSearchPage: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['searchTerm']) {
        this.searchQuery = params['searchTerm'];
        this.isOnSearchPage = true;
      } else {
        this.isOnSearchPage = false;
      }
    });

    this.searchService.clearSearch$.subscribe(() => {
      this.clearSearch();
    });
  }

  onSearchIconClick(): void {
    if (this.isOnSearchPage && this.searchQuery) {
      this.clearSearch();
    } else {
      this.performSearch();
    }
  }

  clearSearch(): void {
    this.searchQuery = '';
    this.router.navigate(['home']); // Navigate to the home or previous page
  }

  performSearch(): void {
    if (this.searchQuery.trim()) {
      this.router.navigate(['search-results'], {
        queryParams: { searchTerm: this.searchQuery }
      });
    }
  }
}
