import { Component, HostBinding } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContentfulService } from '../shared/services/contentful.service';

@Component({
  selector: 'app-author-controls',
  templateUrl: './author-controls.component.html',
  styleUrls: ['./author-controls.component.scss']
})
export class AuthorControlsComponent {
  @HostBinding('class.isExpanded') isExpanded: boolean = true;

  @HostBinding('class.available') isAuthorControlsAvailable: boolean = environment.enablePreview;

  previewsEnabled = false;

  constructor(public contentService: ContentfulService) {
    this.contentService.previewsVisible.subscribe((value) => this.previewsEnabled = value);
  }

  public enablePreview() {
    this.contentService.showPreviews();
  }

  public disablePreview() {
    this.contentService.hidePreviews();
  }
}
