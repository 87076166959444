<div class="search-results">
  <div class="header">
    <h2>{{ resultCount }} Search Results for "{{ searchTerm }}"</h2>
    <button mat-raised-button (click)="clearSearch()">
      Clear Search
    </button>
  </div>
  <div class="tile-container">
    <ng-container *ngFor="let tile of tiles">
      <app-tile [tile]="tile"></app-tile>
    </ng-container>
  </div>
</div>
