import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject, Observable, switchMap, timer
} from 'rxjs';
import { environment } from '../../../environments/environment';

export interface ContentfulResponse<T> {
  items: T[];
}

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private previewsVisibleSubject: BehaviorSubject<boolean>;

  public previewsVisible: Observable<boolean>;

  private contentReloadSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  constructor(private http: HttpClient) {
    const storedPreviewState = sessionStorage.getItem('previewsVisible') === 'true';
    this.previewsVisibleSubject = new BehaviorSubject<boolean>(storedPreviewState);
    this.previewsVisible = this.previewsVisibleSubject.asObservable();
  }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this.previewsEnabled()) {
      headers = headers.set('preview', 'true');
    }
    return headers;
  }

  private previewsEnabled() {
    return environment.enablePreview && this.previewsVisibleSubject.value;
  }

  showPreviews() {
    this.previewsVisibleSubject.next(true);
    sessionStorage.setItem('previewsVisible', 'true');
    this.contentReloadSubject.next();
  }

  hidePreviews() {
    this.previewsVisibleSubject.next(false);
    sessionStorage.setItem('previewsVisible', 'false');
    this.contentReloadSubject.next();
  }

  searchContent(query: string): Observable<object> {
    return this.contentReloadSubject.pipe(
      switchMap(() => {
        const headers = this.getHeaders();
        return this.http.get(`${environment.baseUrl}/api/search?query=${query}`, { headers });
      })
    );
  }

  getContentById(contentId: string, refreshRateInSeconds: number = 2): Observable<object> {
    const url = `${environment.baseUrl}/api/content/${contentId}`;

    return this.contentReloadSubject.pipe(
      switchMap(() => {
        const headers = this.getHeaders();
        if (this.previewsEnabled()) {
          return timer(0, refreshRateInSeconds * 1000).pipe(
            switchMap(() => this.http.get(url, { headers }))
          );
        }
        return this.http.get(url, { headers });
      })
    );
  }

  getContentByType<T>(contentType: string, refreshRateInSeconds: number = 2): Observable<ContentfulResponse<T>> {
    const url = `${environment.baseUrl}/api/contents/${contentType}`;

    return this.contentReloadSubject.pipe(
      switchMap(() => {
        const headers = this.getHeaders();
        if (this.previewsEnabled()) {
          return timer(0, refreshRateInSeconds * 1000).pipe(
            switchMap(() => this.http.get<ContentfulResponse<T>>(url, { headers }))
          );
        }
        return this.http.get<ContentfulResponse<T>>(url, { headers });
      })
    );
  }
}
