import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private _loaderService: LoaderService) { }

  intercept(req: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    // emit onStarted event before request execution
    this._loaderService.onStarted(req);
    return next
      .handle(req)
      // emit onFinished event after request execution
      .pipe(finalize(() => {
        this._loaderService.onFinished(req);
      }));
  }
}
