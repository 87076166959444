import { Environment } from './environment.interface';

export const environment: Environment = {
  // baseUrl: 'http://localhost:3031/users/dms',
  baseUrl: 'http://34.54.91.33',
  contentful: {
    space: '',
    contentfulProxyBaseURL: '',
    environment: '',
  },
  enablePreview: true
};
