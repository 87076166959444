import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private clearSearchSubject = new BehaviorSubject<void>(undefined);

  clearSearch$ = this.clearSearchSubject.asObservable();

  clearSearch(): void {
    this.clearSearchSubject.next();
  }
}
