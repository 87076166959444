<div [ngClass]="align === 'left' ? '' : 'alignRight'">
  <h2>{{heading}}</h2>
  <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedIndexChange)="onTabChange($event)" mat-stretch-tabs="false">
    <mat-tab label="All">
      <div class="tile-container">
        <ng-container *ngFor="let tile of displayedTiles">
          <app-tile [tile]="tile"></app-tile>
        </ng-container>
      </div>
      <div class="paginator" [ngStyle]="{ 'visibility': totalPages > 1 ? 'visible' : 'hidden' }">
        <button mat-icon-button (click)="previousPage()" [disabled]="currentPage === 1">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <span *ngFor="let page of pages"
              [class.active]="page === currentPage"
              (click)="onPageNumberClick(page)">
        {{ page }}
      </span>
        <button mat-icon-button (click)="nextPage()" [disabled]="currentPage === totalPages">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </mat-tab>
    <mat-tab label="Recently Added">
      <div class="tile-container">
        <ng-container *ngFor="let tile of displayedTiles">
          <app-tile [tile]="tile"></app-tile>
        </ng-container>
      </div>
      <div class="paginator" [ngStyle]="{ 'visibility': totalPages > 1 ? 'visible' : 'hidden' }">
        <button mat-icon-button (click)="previousPage()" [disabled]="currentPage === 1">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <span *ngFor="let page of pages"
              [class.active]="page === currentPage"
              (click)="onPageNumberClick(page)">
        {{ page }}
      </span>
        <button mat-icon-button (click)="nextPage()" [disabled]="currentPage === totalPages">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </mat-tab>
    <mat-tab label="Recently Updated">
      <div class="tile-container">
        <ng-container *ngFor="let tile of displayedTiles">
          <app-tile [tile]="tile"></app-tile>
        </ng-container>
      </div>
      <div class="paginator" [ngStyle]="{ 'visibility': totalPages > 1 ? 'visible' : 'hidden' }">
        <button mat-icon-button (click)="previousPage()" [disabled]="currentPage === 1">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <span *ngFor="let page of pages"
              [class.active]="page === currentPage"
              (click)="onPageNumberClick(page)">
        {{ page }}
      </span>
        <button mat-icon-button (click)="nextPage()" [disabled]="currentPage === totalPages">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
