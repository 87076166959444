import {
  Component, Input, OnInit, HostListener, AfterViewInit, SimpleChanges, OnChanges
} from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import {
  NgClass, NgForOf, NgIf, NgStyle
} from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { TileComponent } from '../../../shared/components/tile/tile.component';
import { Tile } from '../../../shared/interfaces/shared-interface';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    TileComponent,
    NgForOf,
    MatIcon,
    MatIconButton,
    NgClass,
    NgIf,
    NgStyle
  ],
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() tiles: Tile[] = [];

  @Input() heading: string = '';

  @Input() route: 'deli-method-sheets' | 'deli-operational-sheets' | undefined;

  @Input() align: 'right' | 'left' = 'left';

  recentlyAdded: Tile[] = [];

  recentlyUpdated: Tile[] = [];

  alphabeticalTiles: Tile[] = [];

  displayedTiles: Tile[] = [];

  currentPage = 1;

  itemsPerPage = 5; // Default value

  pages: number[] = [];

  totalPages: number = 0;

  selectedIndex = 0; // 0 for Alphabetical, 1 for Recently Added, 2 for Recently Updated

  ngOnInit(): void {
    this.sortTiles();
  }

  ngAfterViewInit(): void {
    this.calculateItemsPerPage();
    this.updateDisplayedTiles();
    this.calculatePages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tiles'] && !changes['tiles'].firstChange) {
      this.sortTiles();
      this.updateDisplayedTiles();
      this.calculatePages();
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.calculateItemsPerPage();
    this.calculatePages();

    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages; // Adjust to the last valid page
    }

    this.updateDisplayedTiles();
  }

  sortTiles(): void {
    this.recentlyAdded = [...this.tiles].sort((a, b) => b.dateAdded.getTime() - a.dateAdded.getTime());
    this.recentlyUpdated = [...this.tiles].sort((a, b) => b.dateUpdated.getTime() - a.dateUpdated.getTime());
    this.alphabeticalTiles = [...this.tiles].sort((a, b) => {
      const titleA = a?.title?.toLowerCase() || '';
      const titleB = b?.title?.toLowerCase() || '';
      return titleA.localeCompare(titleB);
    });
  }

  calculateItemsPerPage(): void {
    const tileWidth = 200;

    const buffer = 26;

    const containerWidth = window.innerWidth * 0.85 - 40;
    const itemsPerRow = Math.floor((containerWidth + buffer) / (tileWidth + buffer));

    this.itemsPerPage = itemsPerRow;
  }

  updateDisplayedTiles(): void {
    let currentList;
    if (this.selectedIndex === 0) {
      currentList = this.alphabeticalTiles;
    } else if (this.selectedIndex === 1) {
      currentList = this.recentlyAdded;
    } else {
      currentList = this.recentlyUpdated;
    }
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = this.currentPage * this.itemsPerPage;
    this.displayedTiles = currentList.slice(startIndex, endIndex);
  }

  calculatePages(): void {
    let currentList;
    if (this.selectedIndex === 0) {
      currentList = this.alphabeticalTiles;
    } else if (this.selectedIndex === 1) {
      currentList = this.recentlyAdded;
    } else {
      currentList = this.recentlyUpdated;
    }
    this.totalPages = Math.ceil(currentList.length / this.itemsPerPage);
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  onPageChange(event: any): void {
    this.currentPage = event.pageIndex + 1;
    this.updateDisplayedTiles();
  }

  onTabChange(index: number): void {
    this.selectedIndex = index;
    this.currentPage = 1; // Reset to first page
    this.updateDisplayedTiles();
    this.calculatePages();
  }

  onPageNumberClick(page: number): void {
    this.currentPage = page;
    this.updateDisplayedTiles();
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateDisplayedTiles();
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updateDisplayedTiles();
    }
  }
}
