import {
  HttpContext, HttpHeaders, HttpParams, HttpRequest
} from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor() { }

  // Dummy httpRequest to trigger Loader manually
  private dummyReq: HttpRequest <any> = {
    headers: new HttpHeaders(),
    context: new HttpContext(),
    params: new HttpParams(),
    serializeBody(): string | ArrayBuffer | Blob | FormData | null {
      throw new Error('Function not implemented.');
    },
    detectContentTypeHeader(): string | null {
      throw new Error('Function not implemented.');
    },
    clone(): HttpRequest<any> {
      throw new Error('Function not implemented.');
    },
    body: undefined,
    reportProgress: false,
    withCredentials: false,
    responseType: 'arraybuffer',
    url: '',
    method: '',
    urlWithParams: ''
  };

  onLoadingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Stores all currently active requests.
   */
  private requests: HttpRequest<any>[] = [];

  /**
   * Adds request and notifies observers.
   *
   * @param {HttpRequest} req -for http request.
   */
  onStarted(req: HttpRequest<any>): void {
    this.requests.push(req);
    this.notify();
  }

  /**
   * Removes request from the storage and notifies observers.
   *
   * @param {HttpRequest} req -for http request.
   */
  onFinished(req: HttpRequest<any>): void {
    const index = this.requests.indexOf(req);
    if (index !== -1) {
      this.requests.splice(index, 1);
    }
    this.notify();
  }

  /**
   * Notifies observers about whether there are any requests on fly.
   */
  private notify(): void {
    this.onLoadingChanged.emit(this.requests.length !== 0);
  }

  /**
   * To show the loader directly without any api call.
   */
  showLoader(): void {
    this.onStarted(this.dummyReq);
  }

  /**
   * To hide the loader that load  directly.
   */
  hideLoader(): void {
    this.onFinished(this.dummyReq);
  }
}
