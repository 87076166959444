<button id="hideBtn" tabindex="-1" (click)="isExpanded = !isExpanded">
  <mat-icon>{{isExpanded ? 'chevron_right' : 'chevron_left'}}</mat-icon>
</button>
<div>
  <div class="authorHeading">Author Controls</div>

  <div class="controlWrapper">
    <div>Preview Mode: {{previewsEnabled ? "ENABLED" : "DISABLED"}}</div>
    <button (click)="enablePreview()" tabindex="-1" *ngIf="!previewsEnabled">Enable Preview</button>
    <button (click)="disablePreview()" tabindex="-1" *ngIf="previewsEnabled">Disable Preview</button>
  </div>
</div>
