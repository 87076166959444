<header class="header">
  <div class="logo">
    <img src="assets/png/logo.png" alt="Woolworths Logo" />
  </div>
  <nav class="nav">
    <a routerLink="/home" routerLinkActive="active">Home</a>
    <a routerLink="/deli-method-sheets" routerLinkActive="active">Deli Method Sheets</a>
    <a routerLink="/deli-operational-sheets" routerLinkActive="active">Operational Guides</a>
  </nav>
  <div class="search-bar">
    <form (ngSubmit)="onSearchIconClick()">
      <input type="text" placeholder="Search by name / ref ID" [(ngModel)]="searchQuery" name="searchQuery" />
      <button aria-label="Search" type="submit">
        <mat-icon>{{ isOnSearchPage && searchQuery ? 'close' : 'search' }}</mat-icon>
      </button>
    </form>
  </div>
</header>
