import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchResultsComponent } from './shared/components/search-results/search-results.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'search-results', component: SearchResultsComponent },
  { path: 'home', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule) },
  {
    path: 'deli-method-sheets',
    loadChildren: () => import('./method-sheets/method-sheets.module').then((m) => m.MethodSheetsModule)
  },
  {
    path: 'deli-operational-sheets',
    loadChildren: () => import('./operational-sheets/operational-sheets.module').then((m) => m.OperationalSheetsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
