import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, NgForOf } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { Tile } from '../../interfaces/shared-interface';
import { TileComponent } from '../tile/tile.component';
import { transformTiles } from '../../transformer/tileTransformer';
import { ContentfulService } from '../../services/contentful.service';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-search-results',
  standalone: true,
  templateUrl: './search-results.component.html',
  imports: [
    DatePipe,
    NgForOf,
    TileComponent,
    MatButton
  ],
  styleUrl: './search-results.component.scss'
})
export class SearchResultsComponent implements OnInit {
  tiles: Tile[] = [];

  searchTerm: string = '';

  resultCount: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private contentfulService: ContentfulService,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['searchTerm']) {
        this.searchTerm = params['searchTerm'];
        this.performSearch(this.searchTerm);
      }
    });
  }

  performSearch(searchTerm: string): void {
    this.contentfulService.searchContent(searchTerm)
      .subscribe((response: any) => {
        this.tiles = transformTiles(response.items);
        this.resultCount = this.tiles.length;
      });
  }

  clearSearch(): void {
    this.searchService.clearSearch(); // Trigger the clear search event
    this.router.navigate(['home']);
  }
}
