import { Component, Input } from '@angular/core';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { Tile } from '../../interfaces/shared-interface';

@Component({
  selector: 'app-tile',
  standalone: true,
  imports: [
    DatePipe,
    NgIf,
    NgClass
  ],
  templateUrl: './tile.component.html',
  styleUrl: './tile.component.scss'
})
export class TileComponent {
  @Input() tile: Tile | undefined;

  isPlaceholder: boolean = false;

  constructor(private router: Router) {
  }

  onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = 'assets/png/logo.png';
    this.isPlaceholder = true;
  }

  async navigateToDetail(): Promise<void> {
    await this.router.navigate([this.tile?.route, this.tile?.id]);
  }
}
