<div class="home-page">
  <div class="section top-section">
    <app-pagination [heading]="'Deli Method Sheets'" [tiles]="deliTiles" class="pagination-section top-pagination"></app-pagination>
    <img src="assets/png/DMSHome1.png" alt="Background Image" class="background-image right-align">
  </div>
  <div class="section bottom-section">
    <img src="assets/png/DMSHome2.png" alt="Decorative Image" class="background-image left-align">
    <app-pagination [heading]="'Operational Guides'" [align]="'right'" [tiles]="operationalTiles" class="pagination-section bottom-pagination"></app-pagination>
  </div>
</div>
