import { IDmsMethodSheet, IDmsOperationalGuide } from '../interfaces/contentful';
import { Tile } from '../interfaces/shared-interface';

/**
 * Transforms an array of `IDmsMethodSheet` or `IDmsOperationalGuide` objects
 * into an array of `Tile` objects.
 *
 * @param {Array<IDmsMethodSheet | IDmsOperationalGuide>} value - The input array consisting of `IDmsMethodSheet` or `IDmsOperationalGuide` objects.
 * @returns {Tile[]} - The transformed array of `Tile` objects.
 * @example
 * const input = [
 *   {
 *     sys: { id: '1', createdAt: '2023-08-21T12:34:56Z', updatedAt: '2023-08-22T12:34:56Z', contentType: { sys: { id: 'dmsMethodSheet' } } },
 *     fields: { heading: 'Title 1', finalProductImage: { fields: { file: { url: 'image1.jpg' } } } }
 *   },
 *   {
 *     sys: { id: '2', createdAt: '2023-08-21T12:34:56Z', updatedAt: '2023-08-22T12:34:56Z', contentType: { sys: { id: 'dmsOperationalGuide' } } },
 *     fields: { heading: 'Title 2', image: { fields: { file: { url: 'image2.jpg' } } } }
 *   }
 * ];
 *
 * const output = transformTiles(input);
 * console.log(output);
 * // Output:
 * // [
 * //   {
 * //     id: '1',
 * //     title: 'Title 1',
 * //     dateAdded: Date object representing '2023-08-21T12:34:56Z',
 * //     imageUrl: 'image1.jpg',
 * //     dateUpdated: Date object representing '2023-08-22T12:34:56Z'
 * //   },
 * //   {
 * //     id: '2',
 * //     title: 'Title 2',
 * //     dateAdded: Date object representing '2023-08-21T12:34:56Z',
 * //     imageUrl: 'image2.jpg',
 * //     dateUpdated: Date object representing '2023-08-22T12:34:56Z'
 * //   }
 * // ]
 */
export function transformTiles(value: (IDmsMethodSheet | IDmsOperationalGuide)[]): Tile[] {
  return value.map((sheet): Tile => ({
    id: sheet.sys.id,
    title: sheet.fields.heading ?? '',
    dateAdded: new Date(sheet.sys.createdAt),
    imageUrl: sheet.sys.contentType.sys.id === 'dmsMethodSheet'
      ? (sheet as IDmsMethodSheet)?.fields?.finalProductImage?.fields?.file?.url
      : (sheet as IDmsOperationalGuide)?.fields?.image?.fields?.file?.url,
    dateUpdated: new Date(sheet.sys.updatedAt),
    route: sheet.sys.contentType.sys.id === 'dmsMethodSheet' ? 'deli-method-sheets' : 'deli-operational-sheets'
  }));
}
