import {
  Directive, ElementRef, Input, OnChanges, Renderer2
} from '@angular/core';
import { Document } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulService } from '../services/contentful.service';

@Directive({
  selector: '[appRenderRichText]'
})

/**
 * Content from contentful is sent in document tree format.
 * Apply this directive to a div and pass it the document from contentful to have it render the html within the div it is applied to.
 */
export class RenderRichTextDirective implements OnChanges {
  @Input() appRenderRichText?: Document;

  constructor(private el: ElementRef, private cms: ContentfulService, private renderer: Renderer2) {
    this.renderer.addClass(el.nativeElement, 'contentfulRichText');
  }

  ngOnChanges() {
    if (this.appRenderRichText) {
      this.el.nativeElement.innerHTML = documentToHtmlString(this.appRenderRichText);
    } else {
      this.el.nativeElement.innerHTML = '';
    }
  }
}
