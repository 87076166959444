<div class="tile" (click)="navigateToDetail()">
  <div class="image-container" [ngClass]="{ 'placeholder': isPlaceholder }">
    <img [src]="tile?.imageUrl || 'assets/images/logo.png'" alt="{{ tile?.title }}" (error)="onImageError($event)">
  </div>
  <div class="tile-content">
    <h3>{{ tile?.title }}</h3>
    <p>{{ tile?.dateAdded | date:'dd/MM/yyyy' }}</p>
  </div>
</div>

