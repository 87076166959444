import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
import { RenderRichTextDirective } from './directives/render-rich-text.directive';
import { TruncatePipe } from './pipes/truncate.pipe';

export const WINDOW = new InjectionToken('WINDOW');

@NgModule({
  declarations: [
    RenderRichTextDirective,
    TruncatePipe,
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule
  ],
  exports: [
    TruncatePipe,
    RenderRichTextDirective,
  ],
  providers: [
    {
      provide: WINDOW,
      useValue: window,
    },
  ]
})
export class SharedModule { }
